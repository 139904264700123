import {IOpenComponentProps, PaymentStepDataHook} from '../../../types';
import React from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {NextStepButton} from '../../../StepsManager/Components/NextStepButton/NextStepButton';
import {PaymentsWidget} from '@wix/cashier-payments-widget/lazy';
import {useControllerProps} from '../../../../Widget/ControllerContext';

export const PaymentOpen = ({onCompleted}: IOpenComponentProps) => {
  const localeKeys = useLocaleKeys();
  const {
    paymentStore: {cashierConfiguration},
  } = useControllerProps();
  return (
    <div data-hook={PaymentStepDataHook.open}>
      {cashierConfiguration && (
        <PaymentsWidget
          configuration={cashierConfiguration}
          locale={'en'}
          isWidgetVisible={true}
          theme={'default'}
          shouldApplySiteStyles={false}
          externalSubmitButton={true}
          paymentMethodChanged={/* istanbul ignore next */ (_paymentMethodId) => undefined}
          paymentResult={/* istanbul ignore next */ () => null}
          walletPaymentInNewTab={false}
          isSaveCCEnabled={false}
          allowManualPayment={true}
          allowRecurringPaymentOnly={false}
        />
      )}
      <NextStepButton
        onClick={onCompleted}
        text={localeKeys.checkout.continue_button_label()}
        dataHook={PaymentStepDataHook.continueButton}
      />
    </div>
  );
};
