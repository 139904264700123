import {Button, ButtonSize} from 'wix-ui-tpa';
import React from 'react';
import {classes} from './NextStepButton.st.css';

export interface NextStepButtonProps {
  text: string;
  dataHook: string;
  onClick?: () => void;
}

export const NextStepButton = ({text, dataHook, onClick}: NextStepButtonProps) => (
  <Button
    size={ButtonSize.medium}
    className={classes.NextStepButton}
    onClick={onClick}
    upgrade={true}
    data-hook={dataHook}>
    {text}
  </Button>
);
