import {Step} from '../../StepsManager/Components/Step';
import {PaymentOpen} from './Components/PaymentOpen';
import {PaymentCollapsed} from './Components/PaymentCollapsed';
import {PaymentEmpty} from './Components/PaymentEmpty';
import React from 'react';
import {PaymentStepDataHook, stepImplementationProps} from '../../types';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';

export const PaymentStep = ({index, isLastStep}: stepImplementationProps) => {
  const localeKeys = useLocaleKeys();

  return (
    <Step
      index={index!}
      isLastStep={isLastStep!}
      title={localeKeys.checkout.payment()}
      dataHooks={PaymentStepDataHook}
      openComponent={PaymentOpen}
      collapsedComponent={PaymentCollapsed}
      emptyComponent={PaymentEmpty}
    />
  );
};
