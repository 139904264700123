import {IOpenComponentProps, stepDataHook} from '../../types';
import {StepsContext} from '../StepsManager';
import React, {useContext} from 'react';
import {StepHeader} from './StepHeader';
import {classes} from './Step.st.css';
import {Divider} from 'wix-ui-tpa';

export interface StepProps {
  index: number;
  isLastStep: boolean;
  title: string;
  dataHooks: stepDataHook;
  collapsedComponent?: () => JSX.Element;
  openComponent: (props: IOpenComponentProps) => JSX.Element;
  emptyComponent?: () => JSX.Element;
}

export const Step = ({
  index,
  isLastStep,
  title,
  dataHooks,
  openComponent: OpenComponent,
  collapsedComponent: CollapsedComponent,
  emptyComponent: EmptyComponent,
}: StepProps) => {
  const context = useContext(StepsContext);

  const isEmpty = context.activeStep < index;
  const isCollapsed = context.activeStep > index;
  const isOpen = context.activeStep === index;
  const shouldShowDivider = (isEmpty || isCollapsed) && !isLastStep;

  const onCompleted = () => {
    context.onStepCompleted();
  };

  const onEditClicked = () => {
    context.onStepEdit(index);
  };

  return (
    <div data-hook={dataHooks.root} className={classes.stepContainer}>
      <StepHeader
        isEmpty={isEmpty}
        isCompleted={isCollapsed}
        label={title}
        onEdit={onEditClicked}
        dataHooks={dataHooks}
      />
      {isOpen && <OpenComponent onCompleted={onCompleted} />}
      {isCollapsed && CollapsedComponent && <CollapsedComponent />}
      {isEmpty && EmptyComponent && <EmptyComponent />}
      {shouldShowDivider && (
        <Divider className={classes.stepDivider} data-hook={dataHooks.divider} direction="horizontal" />
      )}
    </div>
  );
};
