import {IOpenComponentProps, PlaceOrderDataHook} from '../../../types';
import React from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {NextStepButton} from '../../../StepsManager/Components/NextStepButton/NextStepButton';

export const PlaceOrderOpen = ({onCompleted}: IOpenComponentProps) => {
  const localeKeys = useLocaleKeys();
  return (
    <div data-hook={PlaceOrderDataHook.open}>
      <div style={{height: 80, width: '100%', textAlign: 'center'}}>Hi, Open state of Place Order</div>

      <NextStepButton
        onClick={onCompleted}
        text={localeKeys.checkout.place_order.place_order_button()}
        dataHook={PlaceOrderDataHook.placeOrderButton}
      />
    </div>
  );
};
