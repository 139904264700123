import {CustomerDetailsOpen} from './Components/CustomerDetailsOpen';
import {CustomerDetailsCollapsed} from './Components/CustomerDetailsCollapsed';
import React from 'react';
import {Step} from '../../StepsManager/Components/Step';
import {CustomerDetailsStepDataHook, stepImplementationProps} from '../../types';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';

export const CustomerDetailsStep = ({index, isLastStep}: stepImplementationProps) => {
  const localeKeys = useLocaleKeys();

  return (
    <Step
      index={index!}
      isLastStep={isLastStep!}
      title={localeKeys.checkout.customer_details()}
      dataHooks={CustomerDetailsStepDataHook}
      openComponent={CustomerDetailsOpen}
      collapsedComponent={CustomerDetailsCollapsed}
    />
  );
};
