import React from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {Text, TextPriority} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {classes} from './ShippingDetails.st.css';
import {getSubdivisionName} from '../../../../domain/utils/localeDataset.util';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {ZipCode} from '../ZipCode/ZipCode';
import {SubdivisionSelector} from '../SubdivisionSelector/SubdivisionSelector';

export enum ShippingDetailsDataHook {
  root = 'ShippingDetails.root',
  title = 'ShippingDetails.title',
  fullName = 'ShippingDetails.fullName',
  email = 'ShippingDetails.email',
  phone = 'ShippingDetails.phone',
  company = 'ShippingDetails.company',
  addressRow = 'ShippingDetails.addressRow',
  customField = 'ShippingDetails.customField',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ShippingDetails = () => {
  const {
    checkoutStore: {checkout, shouldRequireZipCode, shouldShowSubdivisionSelector},
    navigationStore: {isPickupFlow},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const {t} = useTranslation();

  const shouldShowShippingOverBilling = checkout.hasShippableItems && !isPickupFlow;
  const {contact, address} =
    (shouldShowShippingOverBilling ? checkout.shippingDestination : checkout.billingInfo) ?? {};

  const getAddressText = () => {
    const addressWithoutSubdivision = {
      addressLine1: address?.streetAddress?.name
        ? `${address?.streetAddress?.name} ${address?.streetAddress?.number ?? ''}`
        : address?.addressLine,
      addressLine2: address?.addressLine2,
      city: address?.city,
      zipCode: address?.postalCode,
      country: address?.countryFullname,
    };
    const subdivisionKey = getSubdivisionName(address?.country, address?.subdivision);

    if (subdivisionKey) {
      const subdivision = t(subdivisionKey);
      return localeKeys.checkout.shipping_details.delivery_address2({
        ...addressWithoutSubdivision,
        subdivision,
      });
    } else {
      return localeKeys.checkout.shipping_details.delivery_address2_without_subdivision(addressWithoutSubdivision);
    }
  };

  const getFullName = () => {
    return `${contact?.firstName ?? /* istanbul ignore next */ ''} ${
      contact?.lastName ?? /* istanbul ignore next */ ''
    }`;
  };

  return (
    <div data-hook={ShippingDetailsDataHook.root} className={classes.root}>
      <Text tagName={'h3'} className={classes.title} data-hook={ShippingDetailsDataHook.title}>
        {shouldShowShippingOverBilling
          ? localeKeys.checkout.place_order_fast_flow.shipping_details_title()
          : localeKeys.checkout.place_order_fast_flow.billing_details_title()}
      </Text>
      <Text className={classes.content} priority={TextPriority.secondary}>
        <div data-hook={ShippingDetailsDataHook.fullName}>{getFullName()}</div>
        <div data-hook={ShippingDetailsDataHook.company}>{contact?.company ?? ''}</div>
        <div data-hook={ShippingDetailsDataHook.email}>{checkout.buyerInfo.email}</div>
        <div data-hook={ShippingDetailsDataHook.addressRow}>{cleanAddress(getAddressText())}</div>
        <div data-hook={ShippingDetailsDataHook.phone}>{contact?.phone ?? ''}</div>
        {checkout.customField && (
          <div data-hook={ShippingDetailsDataHook.customField}>
            {`${checkout.customField.title}: ${checkout.customField.value}`}
          </div>
        )}
      </Text>
      {shouldRequireZipCode && <ZipCode />}
      {shouldShowSubdivisionSelector && <SubdivisionSelector />}
    </div>
  );
};

export const cleanAddress = (addressLine: string): string => {
  return addressLine
    .split(',')
    .filter((word) => !!word.trim())
    .join(',');
};
