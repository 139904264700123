import {Text, TextButton, TextPriority} from 'wix-ui-tpa';
import React from 'react';
import {stepDataHook} from '../../types';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {classes} from './Step.st.css';

export interface StepHeaderProps {
  isCompleted: boolean;
  isEmpty: boolean;
  label: string;
  onEdit: () => void;
  dataHooks: stepDataHook;
}

export const StepHeader = ({isCompleted, isEmpty, label, onEdit, dataHooks}: StepHeaderProps) => {
  const localeKeys = useLocaleKeys();

  return (
    <div className={classes.stepTitleContainer}>
      <div className={classes.stepTitle}>
        <Text
          tagName={'h2'}
          priority={isEmpty ? TextPriority.secondary : TextPriority.primary}
          className={isEmpty ? classes.emptyStepTitleText : classes.titleText}
          data-hook={dataHooks.header}>
          {label}
        </Text>
      </div>
      {isCompleted && (
        <TextButton
          className={classes.stepEditTitle}
          key={'textButton'}
          onClick={onEdit}
          data-hook={dataHooks.editButton}>
          {localeKeys.checkout.edit()}
        </TextButton>
      )}
    </div>
  );
};
