/* istanbul ignore file: test forms */
import React, {useEffect, useRef, useState} from 'react';
import {FormError, FormValues, FormViewerHandle} from '@wix/form-viewer/widget';
import {Button} from 'wix-ui-tpa';
import {useControllerProps} from '../Widget/ControllerContext';
import {ContactForm, getContactDetailsFromContactFormValues, getContactFormInitialState} from '../Form/ContactForm';

export enum BillingDetailsDataHook {
  root = 'BillingDetails.root',
  contactForm = 'BillingDetails.contactForm',
  addressForm = 'BillingDetails.addressForm',
}

export const BillingDetails = () => {
  const {
    checkoutStore: {setBillingContactDetails, checkout},
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const contactFormViewer = useRef<FormViewerHandle>(null);
  const [contactFormValues, setContactFormValues] = useState<FormValues>(
    getContactFormInitialState(checkoutSettings, checkout.billingInfo?.contact)
  );
  const [contactFormErrors, setContactFormErrors] = useState<FormError[]>([]);

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(contactFormErrors.length > 0);

  const formRefs = [contactFormViewer];

  useEffect(() => {
    setIsSubmitButtonDisabled(contactFormErrors.length > 0);
  }, [contactFormErrors]);

  const submitBillingDetails = async () => {
    await setBillingContactDetails(
      getContactDetailsFromContactFormValues(contactFormValues, checkoutSettings, checkout.shippingDestination?.contact)
    );
  };

  const validateAndSubmit = async () => {
    const areFormsValidArr = await Promise.all(
      formRefs.filter((ref) => !!ref.current).map((ref) => ref.current!.validate())
    );

    if (!areFormsValidArr.includes(false)) {
      await submitBillingDetails();
    }
  };

  return (
    <div data-hook={BillingDetailsDataHook.root}>
      <div data-hook={BillingDetailsDataHook.contactForm}>
        <ContactForm
          formRef={contactFormViewer}
          formValues={contactFormValues}
          formErrors={contactFormErrors}
          setFormValues={setContactFormValues}
          setFormErrors={setContactFormErrors}
        />
      </div>
      <Button disabled={isSubmitButtonDisabled} onClick={() => void validateAndSubmit()}>
        Submit Billing Contact Details
      </Button>
    </div>
  );
};
