import React from 'react';
import {MemberInfoBox} from '../MemberInfoBox/MemberInfoBox';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepsManger} from '../CheckoutSteps/StepsManager/StepsManager';
import {CustomerDetailsStep} from '../CheckoutSteps/Steps/CustomerDetailsStep/CustomerDetailsStep';
import {PaymentStep} from '../CheckoutSteps/Steps/PaymentStep/PaymentStep';
import {PlaceOrderStep} from '../CheckoutSteps/Steps/PlaceOrderStep/PlaceOrderStep';
import {BillingDetails} from '../BillingDetails/BillingDetails';

export enum PickupFlowDataHook {
  root = 'PickupFlow.root',
  title = 'PickupFlow.title',
  expressCheckout = 'PickupFlow.expressCheckout',
}

export const PickupFlow = () => {
  const {
    checkoutStore: {ambassadorCheckout, updateStepOnStage, shouldDisplayExpressCheckout},
  } = useControllerProps();

  const onStepChange = (stepId: number, stepName: string | null) => {
    updateStepOnStage(stepId, stepName);
  };

  return (
    <div data-hook={PickupFlowDataHook.root}>
      {shouldDisplayExpressCheckout && (
        <div data-hook={PickupFlowDataHook.expressCheckout}>
          <ExpressCheckoutButtons checkout={ambassadorCheckout} layout={'horizontal'} />
        </div>
      )}
      <MemberInfoBox />
      <BillingDetails />

      <StepsManger onStepChange={onStepChange}>
        <CustomerDetailsStep />
        <PaymentStep />
        <PlaceOrderStep />
      </StepsManger>
    </div>
  );
};
