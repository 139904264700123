import {Step} from '../../StepsManager/Components/Step';
import React from 'react';
import {PlaceOrderOpen} from './Components/PlaceOrderOpen';
import {PlaceOrderEmpty} from './Components/PlaceOrderEmpty';
import {PlaceOrderDataHook, stepImplementationProps} from '../../types';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';

export const PlaceOrderStep = ({index, isLastStep}: stepImplementationProps) => {
  const localeKeys = useLocaleKeys();

  return (
    <Step
      index={index!}
      isLastStep={isLastStep!}
      title={localeKeys.checkout.place_order.$value()}
      dataHooks={PlaceOrderDataHook}
      openComponent={PlaceOrderOpen}
      emptyComponent={PlaceOrderEmpty}
    />
  );
};
